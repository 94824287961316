import Axios from 'axios';

// console.log('auth inside axios', auth);
export function axiosPost(body = {}, header = {}) {
  return new Promise((resolve, reject) => {
    // const { user } = store.getState();
    let config = {};
    // if (user) {
    config = {
      headers: {
        'jwt-token': '',
        'Content-Type': 'application/json',
        ...header,
      },
      //   };
    };

    Axios.post('https://lezzoo-form-api.herokuapp.com/sendEmail', body, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
