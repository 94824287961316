import './App.css';
import FormScreen from './screens/form';
import LoginScreen from './screens/login';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {
  return (
    <div className='App'>
      <Router>
        <Switch>
          {' '}
          <Route exact path='/'>
            <LoginScreen />
          </Route>
          <Route path='/form'>
            <FormScreen />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
