import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import './styles.css';
import { Form, Input, Button, Select, Radio } from 'antd';
import { notify } from '../../helpers/notify';
import { axiosPost } from '../../axios/axios';

import { Redirect } from 'react-router-dom';
import { auth } from '../../firebase';
const Index = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;

  const [requestType, setRequestType] = useState('');
  const [isSignedIn, setIsSginedIn] = useState(true);
  const [loading, setLoading] = useState(false);

  auth.onAuthStateChanged(function (user) {
    if (!user) {
      setIsSginedIn(false);
    }
  });
  useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        setIsSginedIn(true);
      } else {
        setIsSginedIn(false);
      }
    });
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    // const formData = new FormData();
    // for (const key in values) {
    //   if (values.hasOwnProperty(key)) {
    //     const element = values[key];
    //     formData.append(key, element);
    //   }
    // }
    values.email = auth.currentUser.email;
    values.username = auth.currentUser.displayName;
    axiosPost(values)
      .then((res) => {
        notify(
          'success',
          'Successfully Submitted. Thank you for reaching out!'
        );

        form.setFieldsValue({
          requestType: '',
          featureDescription: '',
          problemDescription: '',
          description: '',
          descriptionTitle: '',
          urgency: '',
        });
        setLoading(false);
      })
      .catch((err) => {
        notify('warning', 'Something went wrong!');
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    notify('warning', 'Something went wrong!');
    setLoading(false);
  };

  return (
    <div>
      {isSignedIn ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 100,
          }}
        >
          <div className='form'>
            <h1 style={{ paddingBottom: 20 }}>Lezzoo Technical Form</h1>

            <Form
              form={form}
              name='basic'
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <div>
                <div className='layout'>
                  <p>
                    <b>Request Type</b>
                  </p>
                  <Form.Item
                    name='requestType'
                    rules={[
                      {
                        required: true,
                        message: 'Please select request type!',
                      },
                    ]}
                    className='dropdown'
                  >
                    <Select onChange={(value) => setRequestType(value)}>
                      <Option value='Feature'>Feature Request</Option>
                      <Option value='Bug Report'>Something is not working</Option>
                      <Option value='Help'>I need help</Option>
                    </Select>
                  </Form.Item>{' '}
                </div>

                <div className='layout'>
                  <p>
                    {' '}
                    <b>Subject</b>
                  </p>

                  <Form.Item
                    className='dropdown'
                    name='descriptionTitle'
                    rules={[
                      {
                        required: true,
                        message: 'Please input subject.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </div>

              {requestType === 'Feature' ? (
                <div className='layout'>
                  <p>
                    <b>Feature Details</b>
                  </p>
                  <Form.Item
                    className='dropdown'
                    name='featureDescription'
                    rules={[
                      {
                        required: true,
                        message: 'Please type feature description!',
                      },
                    ]}
                  >
                    <TextArea />
                  </Form.Item>
                </div>
              ) : null}

              {requestType === 'Problem' ? (
                <div className='layout'>
                  <p>
                    <b>Problem Details</b>
                  </p>
                  <div>
                    <Form.Item
                      className='dropdown'
                      name='problemDescription'
                      rules={[
                        {
                          required: true,
                          message: 'Please type probelm description!',
                        },
                      ]}
                    >
                      <TextArea maxLength={350} />
                    </Form.Item>
                  </div>
                </div>
              ) : null}

              {requestType === 'Other' ? (
                <div className='layout'>
                  <p>
                    <b>Details</b>
                  </p>
                  <Form.Item
                    name='description'
                    className='dropdown'
                    rules={[
                      { required: true, message: 'Please Type Description!' },
                    ]}
                  >
                    <TextArea maxLength={350} />
                  </Form.Item>
                </div>
              ) : null}

              {requestType == 'Other' ? null : (
                <div className='layout'>
                  <p>
                    <b>Urgency</b>
                  </p>
                  <Form.Item
                    name='urgency'
                    className='dropdown'
                    rules={[
                      { required: true, message: 'Please Select Urgency!' },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value='Critical'>Critical</Radio>
                      <Radio value='High'>High</Radio>
                      <Radio value='Low'>Low</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              )}
              <Button loading={loading} type='primary' htmlType='submit'>
                SUBMIT
              </Button>
            </Form>
          </div>
        </div>
      ) : (
        <Redirect to='/' />
      )}
    </div>
  );
};

export default Index;
