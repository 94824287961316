import React from 'react';
import { Button } from 'antd';
import Form from '../../components/requestForm/';
import { auth } from '../../firebase.js';
import { useHistory } from 'react-router-dom';
import { notify } from '../../helpers/notify';
const Index = () => {
  let history = useHistory();
  const signOut = () => {
    auth
      .signOut()
      .then(() => {
        history.push('/');
        notify('success', 'Signed out');
      })
      .catch((error) => {
        notify('warning', 'something went wrong!');
      });
  };
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: 20,
        }}
      >
        <Button onClick={signOut} type='primary'>
          Log Out
        </Button>
      </div>
      <Form />
    </div>
  );
};

export default Index;
