import React from 'react';
import { Button } from 'antd';

import { useHistory } from 'react-router-dom';
import { checkLezzooDomain } from '../helpers/checkLezzooDomain';
import { auth, googleProvider } from '../firebase';
import { notify } from '../helpers/notify';
const Login = () => {
  let history = useHistory();

  const signInWithGoogle = () => {
    auth
      .signInWithPopup(googleProvider)
      .then((res) => {
        if (checkLezzooDomain(res.user.email)) {
          history.push('/form');
        } else {
          auth
            .signOut()
            .then(() => {
              notify('warning', 'Unaothorized Access');
              history.push('/');
            })
            .catch((error) => {
              notify('warning', 'something went wrong!');
            });
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  return (
    <div>
      <Button size='large' onClick={signInWithGoogle}>
        <img
          src='https://img.icons8.com/ios-filled/50/000000/google-logo.png'
          alt='google icon'
          style={{ width: '30px' }}
        />
        <span style={{ marginLeft: 20 }}> Continue with Google</span>
      </Button>
    </div>
  );
};

export default Login;
