import React from 'react';
import LoginButton from '../../components/login';
import Typical from 'react-typical';
const index = () => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#363636',
      }}
    >
      <div>
        <h1
          style={{
            padding: 10,
            color: '#A4A4A4',
            fontSize: 50,
          }}
        >
          <Typical
            steps={['LEZZOO DEVS', 1500, 'LET US HELP YOU!', 1500]}
            loop={Infinity}
            wrapper='p'
          />
        </h1>
      </div>
      <div>
        <h2
          style={{
            color: '#A4A4A4',
          }}
        >
          sign in to proceed..
        </h2>
        <LoginButton />
      </div>
    </div>
  );
};

export default index;
