import firebase from 'firebase/app';
import 'firebase/auth';

export const config = {
  apiKey: 'AIzaSyDYIkvcbkI36XzKtAh-lnoRsS-08wIzog0',
  authDomain: 'lezzoo-tech.firebaseapp.com',
  projectId: 'lezzoo-tech',
  storageBucket: 'lezzoo-tech.appspot.com',
  messagingSenderId: '877035211396',
  appId: '1:877035211396:web:a9f8f1e07446897bbc50d1',
  measurementId: 'G-SE6D5VC409',
};

const app = firebase.initializeApp(config);

export const auth = app.auth();

export const googleProvider = new firebase.auth.GoogleAuthProvider();
